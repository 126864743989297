import React, { Component } from 'react'
import tama from '../../src/images/rabbit.png'
import patreon from '../../content/thumbnails/patreon.png'
import kofi from '../../content/thumbnails/kofi.png'

export default class UserInfo extends Component {
  render() {
    return (
      <aside className="note">
        <div className="container note-container">
          <div className="flex-author">
            <div className="flex-avatar">
              <img className="avatar" src={tama} alt="Tama" />
            </div>
            <div>
              <p>
                {` All articles licensed under CC BY-NC 4.0, and all trademarks used are properties of their respective owners.`}
              </p>

              <div className="flex">
                <a
                  href="https://paypal.me/edytk"
                  className="donate-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={kofi} className="coffee-icon" alt="Coffee icon" />
                  Donasi
                </a>
                <a
                  className="patreon-button"
                  href="mailto:tama@gaptek.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <img src={patreon} height="50" width="50" />  */}
                  Laporkan Hak Cipta
                </a>
              </div>
            </div>
          </div>
        </div>
      </aside>
    )
  }
}
